import Section from '../../components/atoms/Section/Section'
import Row from '../../components/atoms/Row/Row'
import website3 from '../../assets/images/website/website3.jpg'
import website4 from '../../assets/images/website/website4.jpg'
import logoLifeSurfers from '../../assets/images/logo.png'
import funkyBlessVol2 from '../../assets/images/funky_bless.jpg'
import radoscSeksu from '../../assets/images/radosc_seksu.jpg'
import googleImages from '../../assets/images/pcloud.png'
import starterWydarzenie from '../../assets/images/starter_szczecin.jpg'
import SummerCamp2024 from '../../assets/images/summer_camp_2024.jpg'
import SpotkPlansz from '../../assets/images/spotkanie_planszowkowe.jpg'
import forumOgnia5 from '../../assets/images/forum_ognia_5.jpg'
import OdnowaDlaPar from '../../assets/images/odnowa_dla_par.jpg'
import forumOgnia4 from '../../assets/images/forum_ognia_4.jpg'
import forumOgnia6 from '../../assets/images/forum_ognia_6.jpg'
import forumOgnia7 from '../../assets/images/forum_ognia_7.jpg'
import forumOgnia7Poster from '../../assets/images/forum_ognia_7_pion.jpg'
import SummerCamp2025 from '../../assets/images/summer_camp_2025.jpg'
import Button from '../../components/atoms/Forms/Button/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { Helmet } from 'react-helmet'
import { Footer } from './Footer'
import SliderTop from '../../components/molecules/Slider/SliderTop'
import SliderIncomingEvents from '../../components/molecules/Slider/SliderIncomingEvents'
import SliderPastEvents from '../../components/molecules/Slider/SliderPastEvents'
import SliderBottom from '../../components/molecules/Slider/SliderBottom'
import Contacts from '../../components/organisms/Contacts/Contacts'
import PastEventsTable from '../../components/molecules/Table/PastEventsTable'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import { Grid, TextField } from '@mui/material'
import React, { useState } from 'react'
import sprawozdanieFinansowe from '../../assets/files/sprawdozdanieFinansowe.pdf'
import sprawozdanieFinansowe2023 from '../../assets/files/Sprawozdanie finansowe 2023.pdf'
import sprawozdanieMerytoryczne from '../../assets/files/sprawozdanieMerytoryczne.pdf'
import sprawozdanieMerytoryczne2023 from '../../assets/files/Sprawozdanie merytoryczne 2023.pdf'
import statut from '../../assets/files/statut.pdf'
import polityka from '../../assets/files/polityka.pdf'
import darowizny from '../../assets/files/darowizny.pdf'
// import regulamin from '../../assets/files/regulamin.pdf'
import { useHistory } from 'react-router-dom'

const currentYear = new Date().getFullYear()
const HomePage = (props) => {
    const history = useHistory()
    const handleGoToPaymentRules = () => {
      history.push('/regulamin-wplat')
    }

  const t = LocalStorageHelper.get('translation')
  const [totalAmount, setTotalAmout] = useState(null)
  const samplePastEventsLeft = [
    { id: 1, name: 'Wakacyjny wyjazd dla małżeństw', date: 'Luty 2025', detailsUrl: 'https://www.facebook.com/events/1538654830100897', imageUrl: radoscSeksu },
    { id: 2, name: 'VI Forum Ogień z Nieba', date: 'Listopad 2024', detailsUrl: 'https://forumognia.pl/', imageUrl: forumOgnia6 },
    { id: 3, name: 'Summer Camp', date: 'Wrzesień 2024', detailsUrl: 'https://www.facebook.com/events/1829128537591677/', imageUrl: SummerCamp2024 },
    { id: 4, name: 'Spotkania planszówkowe', date: 'Styczeń-Czerwiec 2024', detailsUrl: 'https://www.facebook.com/events/556223079715565/557954902875716', imageUrl: SpotkPlansz },
    { id: 5, name: 'V Forum Ogień z Nieba', date: 'Listopad 2023', detailsUrl: 'https://www.facebook.com/events/229736963376103/', imageUrl: forumOgnia5 },
  ]
  const samplePastEventsRight = [
    { id: 1, name: 'ODnowa dla par', date: 'Kwiecień 2023', detailsUrl: 'https://www.facebook.com/events/3392410574308497/', imageUrl: OdnowaDlaPar },
    { id: 2, name: 'IV Forum Ogień z Nieba', date: 'Listopad 2021', detailsUrl: 'https://www.facebook.com/events/359277809207412/', imageUrl: forumOgnia4 },
    { id: 3, name: '#Starter', date: 'Wrzesień 2020', detailsUrl: 'https://www.facebook.com/events/749247135874073/', imageUrl: starterWydarzenie },
    { id: 4, name: 'Funky Bless vol.2', date: 'Styczeń 2020', detailsUrl: 'https://www.facebook.com/events/589849078239767/', imageUrl: funkyBlessVol2 },
    { id: 5, name: 'Inne wydarzenia', date: `2017-${currentYear}`, detailsUrl: 'https://www.facebook.com/lifesurferss/past_hosted_events', imageUrl: logoLifeSurfers },
  ]
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lifesurfers</title>
        <meta name="keywords" content="gry planszowe, Szczecin, planszówki Lifesurfers" />
        <meta
          name="description"
          content="Zarejestruj się i stwórz, dołącz do najbliższego wydarzenia, aby poczuć magię planszówek. Pamiętaj, nie liczy się Twój wiek czy doświadczenie w graniu."
        />
        <meta name="subject" content="Lifesurfers" />
        <meta name="copyright" content="Lifesurfers" />
        <meta name="language" content="PL" />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Jan Szymański, kontakt@your-site.pl" />
        <meta name="designer" content="Jan Szymański, kontakt@your-site.pl" />
        <meta name="copyright" content="Jan Szymański, kontakt@your-site.pl" />
        <meta name="owner" content="Jan Szymański, kontakt@your-site.pl" />
        <meta name="url" content="https://aplikacja.lifesurfers.pl" />
        <meta name="identifier-URL" content="https://aplikacja.lifesurfers.pl" />
        <meta name="category" content="Gry planszowe" />
        <meta name="distribution" content="Global" />
        <meta name="rating" content="General" />
        <meta name="revisit-after" content="7 days" />
        <meta httpEquiv="Expires" content="0" />
        <meta httpEquiv="Pragma" content="no-cache" />
        <meta httpEquiv="Cache-Control" content="no-cache" />
        <meta name="og:title" content="Lifesurfers" />
        <meta name="og:type" content="website" />
        <meta name="og:url" content="https://aplikacja.lifesurfers.pl" />
        <meta name="og:site_name" content="Lifesurfers" />
        <meta
          name="og:description"
          content="Zarejestruj się i stwórz, dołącz do najbliższego wydarzenia, aby poczuć magię planszówek. Pamiętaj, nie liczy się Twój wiek czy doświadczenie w graniu."
        />
        <meta name="fb:page_id" content="lifesurfers" />
        <meta name="og:email" content="fundacja@lifesurfers.pl" />
        <meta name="og:phone_number" content="500377540" />
        <meta name="og:locality" content="Szczecin" />
        <meta name="og:region" content="Zachodniopomorskie" />
        <meta name="og:postal-code" content="71-600" />
        <meta name="og:country-name" content="Polska" />
        <meta property="og:type" content="website" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta content="yes" name="apple-touch-fullscreen" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="format-detection" content="telephone=no" />
      </Helmet>
      <SliderTop {...props} />
      <Row
        right={<Section image={website4} />}
        left={
          <Section
            title="Kilka słów, na temat tego co robimy?"
            object={
              <>
                <p>
                  Fundacja Life Surfers powstała z pasji do pomagania młodym ludziom w utrzymywaniu się na falach życia
                  i osiąganiu swoich celów. Nazwa naszej fundacji doskonale oddaje nasze dążenie do udzielania wsparcia,
                  byśmy wszyscy mogli płynąć przez życie i osiągać sukcesy. Opieramy się na wartościach
                  chrześcijańskich, które stanowią fundament naszych działań.
                </p>
                <p>
                  Mimo że każdy z nas ma swoją rodzinę, pracę i obowiązki, poświęcamy swój czas, pieniądze i
                  zaangażowanie, aby dawać nadzieję innym. Nasza fundacja została oficjalnie zarejestrowana w KRS 4
                  grudnia 2017 roku, co umożliwiło nam podejmowanie działań w sposób formalny.{' '}
                </p>
                <p>
                  W 2022 roku otrzymaliśmy status Organizacji Pożytku Publicznego, co pozwala naszym darczyńcom na
                  odliczenie przekazanych kwot od podatku oraz przekazanie 1,5% swojego rozliczenia rocznego.{' '}
                </p>
                <p>
                  Na stronie głównej naszej fundacji warto byłoby zaktualizować plan na nadchodzący rok, aby
                  odzwierciedlał nasze najnowsze cele i działania.
                </p>
                <p>
                  Skupiamy się na wspieraniu i angażowaniu młodych ludzi, dlatego oferujemy im bezpośrednie wsparcie w
                  ich codziennym życiu. Poprzez tworzenie i wspieranie wspólnot oraz grup młodzieżowych, staramy się
                  rozwijać ich kreatywność i samodzielność, aby w przyszłości mogli samodzielnie podejmować inicjatywy.
                  Naszym celem jest także rozbudzenie wrażliwości młodzieży na potrzeby innych oraz wspieranie działań
                  prospołecznych. Dlatego stawiamy na wolontariat jako najlepszą formę wychowania i rozwoju potencjału
                  młodych ludzi.
                </p>
                <p>
                  Regularnie organizujemy cotygodniowe spotkania, na które serdecznie zapraszamy. Jeśli jesteś
                  zainteresowany/a, napisz do nas, a przekażemy Ci szczegółowe informacje.
                </p>
              </>
            }
          />
        }
      />
      <SliderIncomingEvents {...props} />
      <Row
        left={
          <>
            <Section image={SummerCamp2025} />
          </>
        }
        right={
          <Section
            title="Summer Camp 2025"
            object={
              <>
                  <p>
                  Zapraszamy na sierpniowy wyjazd wakacyjny, który połączy przygodę, wspólnotę i duchowy rozwój!
                  Przez kilka dni będziemy przemierzać malownicze zakątki rzeki Drawy, ciesząc się pięknem natury, wspólną modlitwą i wartościowymi rozmowami.
                  Co Cię czeka?<br/>
                  </p>
                  <p>
                  <br />
                  🚣🏽‍♀️Spływ kajakowy pełen wyzwań i radości<br/>
                  🔥Ognisko, śpiew i inspirujące rozmowy (możesz zabrać ze sobą instrument)<br/>
                  🙏Czas na refleksję, Słowo Boże i budowanie relacji<br/>
                  ☀️Wspaniała atmosfera i niezapomniane wspomnienia<br/><br/>
                  To nie tylko przygoda - to czas, który umocni Twoją wiarę i pozwoli Ci odpocząć od codziennego zgiełku.<br/>
                  Dla tych, którzy nie mogą wypłynąć też jest możliwość spędzenia wspólnego czasu na miejscu ⛱️<br/>
                  </p>
                  <p>
                  Szczegółowy plan niebawem! 😇 <br/>
                  📢Zapisz się już teraz! Liczba miejsc ograniczona.
                  <br />
                </p>
                <Button
                    sx={{ minWidth: BreakpointsHelper.isMobile() ? '100%' : '100px' }}
                    onClick={() =>
                      window.open(
                        'https://www.facebook.com/events/449059288228405',
                        '_blank'
                      )
                    }
                  >
                    Dowiedz się więcej
                  </Button>
              </>
            }
          />
        }
      />
      <Row
        right={
          <>
            <Section image={forumOgnia7Poster} />
          </>
        }
        left={
          <Section
            title="Forum Ogień z Nieba - Wstań i idź..."
            object={
              <>
                  <p>
                  Inicjatywa &quot;Forum Ogień z Nieba&quot; zrodziła się jako odpowiedź na pragnienie Jezusa zawarte w słowach:
                  „Przyszedłem rzucić ogień na ziemię i jakże bardzo pragnę, żeby on już zapłonął” (Łk 12, 49).
                  Ten ogień Ducha Świętego pragniemy nieść dalej, rozpalając serca i wiarę.
                  </p>
                  <p>
                  W tym roku pragniemy szczególnie wsłuchać się w słowa Jezusa skierowane do uzdrowionego Samarytanina:
                  <b> &quot;Wstań i idź...&quot; (Łk 17, 19)</b>,
                  , które stały się hasłem przewodnim nadchodzącej edycji Forum. Podobnie jak dziesięciu trędowatych wołało do Jezusa o miłosierdzie z daleka (por. Łk 17, 13),
                  tak i my podczas Forum pragniemy wołać o wylanie Ducha Świętego, o uzdrowienie i oczyszczenie.
                  Chcemy, aby to spotkanie było przestrzenią osobistego spotkania z Jezusem – Mistrzem, który lituje się nad nami.
                  Pragniemy, jak uzdrowiony Samarytanin, doświadczyć Jego mocy, wrócić do Niego z wdzięcznością, chwaląc Boga donośnym głosem
                  (por. Łk 17, 15-16) i usłyszeć osobiście zachętę: &quot;Wstań i idź!&quot;.  Niech nasza wiara, umocniona przez uwielbienie,
                  głoszone konferencje i świadectwa, żywe słowo Boże, Eucharystię i modlitwę, nie będzie letnia ani powierzchowna, ale żywa i odważna
                  – taka, która uzdrawia i posyła do działania. Chcemy dzielić się tym doświadczeniem wiary, która podnosi i ratuje, zwłaszcza z tymi,
                  którzy czują się jak Samarytanin – może trochę na uboczu, a jednak umiłowani przez Boga.
                  </p>
                  <p>
                  Forum Ogień z Nieba jest całodniowym, biletowanym wydarzeniem organizowanym przez naszą Fundację przy wsparciu wielu
                  szczecińskich wspólnot i udziale znanych gości z kraju i zagranicy. W listopadzie 2025 roku, pod hasłem:
                  &quot;Wstań i idź...&quot;, odbędzie się już <b>VII edycja</b>, odbędzie się już VII edycja tego wyjątkowego spotkania.
                  Zapraszamy Cię, byś razem z nami powstał i poszedł drogą wiary!

                  <br />
                  {/* <br /> Więcej informacji na stronie:
                  <b>
                    <a href="https://www.forumognia.pl/" target="_blank" rel="noreferrer">
                      {' '}
                      https://www.forumognia.pl
                    </a>
                  </b> */}
                </p>
                <Button
                    sx={{ minWidth: BreakpointsHelper.isMobile() ? '100%' : '100px' }}
                    onClick={() =>
                      window.open(
                        'https://forumognia.pl/',
                        '_blank'
                      )
                    }
                  >
                    Dowiedz się więcej
                  </Button>
              </>
            }
          />
        }
      />
<SliderPastEvents {...props} />
<div>
      {/* ... */}
      <Row
          left={
            <div >
              <Typography
                variant="h4"
                component="h6"
                align="center"
                gutterBottom
                sx={{ fontWeight: 'bold', color: '#ce0707', mb: -5 }}
              >
                {/* W 2024 roku */}
              </Typography>
              <Section
                object={
                  <PastEventsTable
                    pastEvents={samplePastEventsLeft}
                  />
                }
              />
            </div>
          }
          right={
            <div>
              <Typography
                variant="h4"
                component="h2"
                align="center"
                gutterBottom
                sx={{ fontWeight: 'bold', color: '#ce0707', mb: -5 }}
              >
                {/* W 2023 roku */}
              </Typography>
              <Section
                object={
                  <PastEventsTable
                    pastEvents={samplePastEventsRight}
                  />
                }
              />
            </div>
          }
      />
      {/* ... */}
    </div>
      <SliderBottom {...props} />
      <Row
        revertMobile
        left={
          <>
            <Section
              title="Sprawdź co robimy dla Ciebie"
              object={
                <>
                  <p>
                    Oto przedstawienie naszej fundacji Life Surfers oraz naszych głównych działań, które podejmujemy,
                    aby pomagać młodym ludziom utrzymać się na falach życia i osiągnąć swoje cele:
                  </p>
                  <p>
                    <b>DLA MŁODYCH</b>
                  </p>
                  <ul>
                    <li>warsztaty</li>
                    <li>pogadanki</li>
                    <li>wyjazdy</li>
                    <li>koncerty</li>
                    <li>spływy</li>
                    <li>konferencje</li>
                    <li>wolontariat</li>
                    <li>klub młodzieżowy (w planie)</li>
                  </ul>
                  <p>
                    <b>DLA MAŁŻEŃSTW</b>
                  </p>
                  <ul>
                    <li>spotkania tematyczne</li>
                    <li>warsztaty</li>
                    <li>odpoczynek wakacyjny</li>
                    <li>poradnictwo</li>
                  </ul>
                  <p>
                    <b>DLA POTRZEBUJĄCYCH</b>
                  </p>
                  <ul>
                    <li>pomoc materialna</li>
                    <li>wsparcie finansowe</li>
                    <li>akcje wolontaryjne</li>
                    <li>zbiórki</li>
                  </ul>
                </>
              }
            />
          </>
        }
        right={<Section image={website3} />}
      />
      <Row
        right={
          <>
            <Section
              title="Zobacz nasze zdjęcia "
              object={
                <>
                  <p>
                    Zapraszamy do zapoznania się z naszą galerią zdjęć, która ukazuje niezwykłe chwile i działania
                    Fundacji Life Surfers. Przez te zdjęcia chcemy podzielić się z Wami naszą pasją, zaangażowaniem i
                    radością, jaką czerpiemy z pomagania młodym ludziom utrzymać się na falach życia.
                  </p>
                  <p>
                    Nasze zdjęcia przedstawiają różnorodne wydarzenia i aktywności, które odbywają się pod egidą
                    Fundacji. Możecie zobaczyć uśmiechnięte twarze młodzieży, biorącej udział w naszych cotygodniowych
                    spotkaniach. To tam, poprzez tworzenie i wspieranie wspólnoty oraz grup młodzieżowych, rozwijamy ich
                    kreatywność, samodzielność i dajemy im szansę na rozwój.
                  </p>
                  <p>
                    W naszej galerii znajdziecie także zdjęcia z różnorodnych inicjatyw prospołecznych, w których
                    młodzież angażuje się jako wolontariusze. Naszym celem jest uwrażliwienie na potrzeby innych ludzi i
                    wspieranie działań, które mają pozytywny wpływ na społeczność. Te zdjęcia ukazują młodych serferów
                    życia w akcji, której owocem są uśmiechy i wdzięczność osób, którym pomagamy.
                  </p>
                  <p>
                    Przez galerię chcemy pokazać, że nasza fundacja nie tylko istnieje formalnie, ale także działa na
                    realnych płaszczyznach, angażując się w życie młodych ludzi i społeczności. Nasza praca oparta na
                    wartościach chrześcijańskich, oddana z pasją i zaangażowaniem, jest kontynuacją naszej misji
                    pomagania i dawania nadziei.
                  </p>
                  <p>
                    Zapraszamy do oglądania naszej galerii zdjęć, aby poczuć atmosferę, która towarzyszy naszym
                    działaniom. Dziękujemy wszystkim, którzy wspierają nasze wysiłki i wierzą w potencjał młodych ludzi.
                    Jesteśmy dumni z naszych serferów życia i razem tworzymy piękne fale, na których możemy pływać przez
                    życie i osiągać nasze cele.
                  </p>
                  <Button
                    sx={{ minWidth: BreakpointsHelper.isMobile() ? '100%' : '100px' }}
                    onClick={() =>
                      window.open(
                        'https://e.pcloud.link/publink/show?code=kZ61BsZ5acItcUbXuhjPW6Fk9N4kRHOrbMV&fbclid=IwAR2oiwGpYjBWgIPogSRwIIBxo5QR4_yP_iEDadvgisIDcbAZ2I568Vmn0z0',
                        '_blank'
                      )
                    }
                  >
                    Zobacz naszą galerię zdjęć
                  </Button>
                </>
              }
            />
          </>
        }
        left={<Section image={googleImages} />}
      />
      <Row
        background
        revertMobile
        left={
          <Section
            title="Kontakt"
            object={
              <>
                <p>
                  <b>Adres:</b>
                  <br />
                  Fundacja Life Surfers
                  <br />
                  ul. Parkowa 1/3, 71-600 Szczecin
                  <br />
                  <b>NIP:</b> 8522638007
                  <br />
                  <b>REGON:</b> 368901643
                  <br />
                  <b>KRS:</b> 0000707010
                  <br />
                </p>
                <p>
                  <b>Numer konta bankowego:</b>
                  <br />
                  BNP Paribas 23 1600 1462 1744 8476 0000 0001
                  <br />
                </p>
                <p>
                  <b>Telefon:</b> +48 500 377 540
                  <br />
                  <b>Email:</b> fundacja@lifesurfers.pl
                  <br />
                </p>
                <Contacts homePage />
              </>
            }
          />
        }
        right={
          <Section
            object={
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2376.9254631298463!2d14.565688951627147!3d53.43404097989936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47aa09734598760f%3A0xc37058eab4a5c60a!2sParkowa%201%2C%2071-600%20Szczecin!5e0!3m2!1sen!2spl!4v1672480086456!5m2!1sen!2spl"
                width="100%"
                height="600"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            }
          />
        }
      />
<Footer {...props} />
    </div>
  )
}

export default HomePage
