import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

// Przyjmuje tablicę minionych wydarzeń jako prop
const PastEventsTable = ({ pastEvents = [], defaultPlaceholder = null }) => {
  if (!pastEvents || pastEvents.length === 0) {
    return <Typography variant="h6">Brak danych o minionych wydarzeniach.</Typography>
  }

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none', border: '1px solid #eee' }}>
      <Table aria-label="tabela minionych wydarzeń">
        <TableBody>
          {pastEvents.map((event) => (
            <TableRow
              key={event.id}
              sx={{
                '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
                '&:last-child td, &:last-child th': { border: 0 },
                '& td, & th': {
                    borderBottom: 'none',
                    verticalAlign: 'middle',
                    paddingTop: '4px',
                    paddingBottom: '4px',
                  }
              }}
            >
              <TableCell align="center" sx={{ padding: '8px', width: '20%' }}>
                <Box
                  component="img"
                  sx={{
                    height: 200,
                    width: 200,
                    borderRadius: '4px',
                    objectFit: 'cover',
                    display: 'block',
                    margin: '0 auto',
                    backgroundColor: '#eee'
                  }}
                  alt={`Miniatura ${event.name}`}
                  src={event.imageUrl || defaultPlaceholder || ''}
                />
              </TableCell>

              {/* Komórka zawierająca nazwę i datę */}
              <TableCell component="th" scope="row" sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
                {/* Nazwa wydarzenia */}
                <Typography component="div" sx={{ fontWeight: '700', fontSize: '1.4rem', mb: 0.5 }}>
                  {event.name}
                </Typography>
                {/* Data wydarzenia - POGRUBIONA */}
                <Typography variant="body1" display="block" color="text.secondary" sx={{ fontWeight: 'bold' }}> {/* Zmieniono fontWeight */}
                  {event.date}
                </Typography>
              </TableCell>

              {/* Opcjonalna komórka na link "Zobacz więcej" */}
              {event.detailsUrl && (
                <TableCell align="center" sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
                  {/* Link - POGRUBIONY I WIĘKSZY */}
                  <Link href={event.detailsUrl} target="_blank" rel="noopener" underline="hover" sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}> {/* Zmieniono fontWeight i fontSize */}
                    Zobacz więcej
                  </Link>
                </TableCell>
              )}
              {/* Pusta komórka dla wyrównania */}
              {!event.detailsUrl && <TableCell sx={{ paddingLeft: '16px', paddingRight: '16px' }} />}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PastEventsTable
